<template>
  <header class="kb-header" id="kb-header" v-if="!isLoading" style="border-bottom:none;">
    <div v-if="!isSearchAreaShow" class="header-component">

      <div class="header-column header-title">
        {{boardMst.boardTitle}}
      </div>
      <div class="header-column header-util right">
        <div class="util util-pencil" v-if="boardMst.postWrtYn == 'Y'">
          <a @click="clickWrite" class="util-actions util-actions-pencil">
            <i class="icon-pencil"></i>
          </a>
        </div>
        <div class="util util-search" v-if="boardMst.srchTitleYn == 'Y'">
          <a @click="isSearchAreaShow = true" class="util-actions util-actions-search">
            <i class="icon-search"></i>
          </a>
        </div>
      </div>
    </div>

    <!-- 검색영역  -->
    <div v-if="isSearchAreaShow" class="header-component">
      <div class="header-column header-util left">
        <div class="util util-back">
          <a @click="clearSearch" class="util-actions util-actions-back">
            <i class="icon-history-back"></i>
          </a>
        </div>
        <div class="util util-search-input">
          <input type="text" v-model="search.title" placeholder="제목을 검색하세요">
        </div>
      </div>
      <div class="header-column header-util right">
        <div class="util util-search">
          <a href="javascript:" @click="clickSearch" class="util-actions util-actions-search">
            <i class="icon-search"></i>
          </a>
        </div>
      </div>
    </div>
    <!-- //검색영역  -->
  </header>

    <div class="main-header"  v-if="!isLoading">
      <!-- 카테고리 -->
      <div v-if="boardMst.srchCateYn == 'Y'" class="header-snb">
        <nav class="snb">
          <ul class="snb-list">
            <li v-for="(item, idx) in boardCateList" :key="item.boardCateSn" class="snb-item" :class="{'is-active' : idx === 0}">
              <button @click="clickCategory(item, idx)">{{ item.cateNm }}</button>
            </li>
          </ul>
        </nav>
      </div>
      <!-- 상단 안내 메시지 -->
      <section v-if="boardMst.boardTopCn" class="segment-box-outline-xl mx-2 my-2 p-3">
        <div v-html="boardMst.boardTopCn" class="ck-content"></div>
      </section>

    </div>
    <!-- 리스트 영역 -->
    <div class="main-content" id = "kb-board-master">
      <div class="list-top status">
        <div class="top-column">
          <p class="text text-muted">총 {{ listTotalCount }}건</p>
        </div>
      </div>
      <board-main-list ref="boardListComponent" v-if="boardMst.boardLayoutCd == 'mainList'" :board-mst-map="boardMst" :board-cate-list="boardCateList" :is-oper="isOper" :post-grp-no="postGrpNo" v-model:list-total-count="listTotalCount" />
      <board-timeline ref="boardListComponent" v-if="boardMst.boardLayoutCd == 'timeline'" :board-mst-map="boardMst" :board-cate-list="boardCateList" :is-oper="isOper" :post-grp-no="postGrpNo" v-model:list-total-count="listTotalCount" />
      <board-thumb-list ref="boardListComponent" v-if="boardMst.boardLayoutCd == 'thumbList'" :board-mst-map="boardMst" :board-cate-list="boardCateList" :is-oper="isOper" :post-grp-no="postGrpNo" v-model:list-total-count="listTotalCount" />
      <board-group-list ref="boardListComponent" v-if="boardMst.boardLayoutCd == 'groupList'" :board-mst-map="boardMst" :board-cate-list="boardCateList" :is-oper="isOper" :post-grp-no="postGrpNo" v-model:list-total-count="listTotalCount" />
    </div>
   <LoadingDiv v-if="isLoading"/>
    <!-- 하단 안내 메시지 -->
    <section v-if="boardMst.boardBtmCn" class="segment-box-outline-xl mx-2 my-4 p-3">
      <div v-html="boardMst.boardBtmCn" class="ck-content"></div>
    </section>

</template>

<script>
import {computed, onMounted, reactive, ref, watch} from "vue";
import {useStore} from 'vuex';
import {useRoute} from "vue-router";
import {useAlert} from "@/assets/js/modules/common/alert";
import boardMainList from '@/components/board/mobile/BoardMainList';
import boardTimeline from '@/components/board/mobile/BoardTimeline';
import boardThumbList from "@/components/board/mobile/BoardThumbList";
import boardGroupList from "@/components/board/mobile/BoardGroupList";
import {goBack} from '@/assets/js/modules/common/common';
import {
  checkIsOper,
  getBoardCateList,
  getBoardMstInfo,
  goToWrite,
  validTarget
} from "@/assets/js/modules/board/board-common";
import LoadingDiv from "@/components/common/LoadingDiv";

export default {
  name: 'boardMst',
  components: {boardMainList, boardTimeline, boardThumbList, boardGroupList,LoadingDiv},
  props:{
    boardId: String
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const {showMessage} = useAlert();

    const session = computed(() => store.state.auth.session);
    const boardMst = ref({   })
    const postGrpNo = ref(boardMst.value.postGrpNo);
    const boardId = computed(() => props.boardId);


    const boardCateList = ref([]);
    const isOper = ref(false);
    const isLoading = ref(true);
    const isSearchAreaShow = ref(false);
    const search = reactive({
      category: {cateNm:'카테고리', boardCateSn:''},
      title: '',
    });
    const listTotalCount = ref(0);
    const boardListComponent = ref(null);

    const clickWrite = () => {
      goToWrite(session.value.lrnerId, boardMst);
    }

    const clickSearch = () => {
      let params = { boardMstSn: boardMst.value.boardMstSn };
      if(boardMst.value.srchTitleYn == 'Y' && search.title != '') {
        params['postTitle'] = search.title;
      }
      if(boardMst.value.srchCateYn == 'Y' && search.category.boardCateSn > 0) {
        params['boardCateSn'] = search.category.boardCateSn;
      }
      if(boardListComponent.value.paging) {
        boardListComponent.value.paging.pageNo = 1;
      }
      boardListComponent.value.getBoardList(params);
    }

    const clickCategory = (item, idx) => {
      search.category.cateNm = item.cateNm;
      search.category.boardCateSn = item.boardCateSn;
      document.querySelectorAll('.snb-item').forEach(x => x.classList.remove('is-active'))
      document.querySelectorAll('.snb-item')[idx].classList.add('is-active');
      clickSearch();
    }

    const clearSearch = () => {
      search.title = '';
      isSearchAreaShow.value = false;
      clickSearch();
    }

    const checkValidAccess = () => {
      checkIsOper({boardMstSn: boardMst.value.boardMstSn, userSn: store.state.auth.session.userSn}, isOper, () => {
        if(!validTarget(boardMst.value.postViewTrgt, isOper.value)){
          showMessage('게시글 조회 권한이 없습니다.', () => {goBack()});
        }else{
          isLoading.value = false;
        }
      });
    }

    // 게시판 정보
    const getBoardMst = () => {
      isLoading.value = true;
      getBoardMstInfo(boardId.value, boardMst, () => {
        if(route.params.postGrpNo){
          // 글쓰기 버튼 숨김
          boardMst.value.postWrtYn = 'N';
        }
        getBoardCateList(boardMst.value.boardMstSn, boardCateList, checkValidAccess);
      });
    }

    watch(() => props.boardId, () => {
      if(route.name == 'BoardMst') getBoardMst();
    })
    watch(() =>props.postGrpNo, () => {
      if(route.name == 'BoardMst') getBoardMst();
    })

    onMounted(() => {getBoardMst()});

    return{
      boardMst,
      boardCateList,
      isOper,
      postGrpNo,
      isLoading,
      isSearchAreaShow,
      search,
      listTotalCount,
      boardListComponent,
      showHistoryBack: route.query.hisback == '1' ? true : false,
      clickWrite,
      clickSearch,
      clickCategory,
      clearSearch,
      goBack,
    }

  },
}
</script>