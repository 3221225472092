<template>
  <main class="kb-main" id="kb-support">
    <LxpMobileHeader title="기업문화 페스티벌">
      <template v-slot:left>
        <div class="util util-back">
          <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </template>
      <template v-slot:right>

      </template>
    </LxpMobileHeader>

      <div class="main-content main-component">
        <template v-if="tabMenu <= 0">

          <div class="intro-section" >

            <div class="kb-main" >
              <nav class="kb-nav-round-tabs"  style="padding:10px; margin-bottom: 0px;">
                <ul class="kb-nav-list">
                  <li class="kb-nav-item">
                    <a href="#" class="kb-nav-link" :class="{ 'is-active' : menu===1 }" @click="menu=1"><span class="kb-nav-text">HR부(인재개발) 제작</span></a>
                  </li>
                  <li class="kb-nav-item">
                    <a href="#" class="kb-nav-link" :class="{ 'is-active' : menu===2 }" @click="menu=2"><span class="kb-nav-text">크리에이터 제작</span></a>
                  </li>

                </ul>
              </nav>
              <div v-if="menu===1" class="main-content min-component" style="padding-bottom: 20px;">
                <MobileBoardReadOnly
                    :board-id="boardId[0]"
                />
              </div>
              <div v-if="menu===2" class="main-content min-component" style="padding-bottom: 20px;">
                <MobileBoardReadOnly
                    :board-id="boardId[1]"
                />
              </div>

            </div>


          </div>

        </template>

    </div>

  </main>
</template>

<script>
import {useStore} from 'vuex';
import {computed, reactive, ref} from 'vue';
import {
  closeToggles,
  getDcdOptions,
} from '@/assets/js/ui.init';
import {
  initProgressBar,
  timestampToDateFormat
} from '@/assets/js/util';
import {
  banerExpsrLocCdDcds,
} from '@/assets/js/modules/baner/common-baner';
// import CommonBanner from '@/components/common/CommonBanner';

// import BoardReadOnly from '@/components/board/BoardReadOnly'
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";
import MobileBoardReadOnly from "@/components/board/mobile/BoardReadOnly";
// import HrdCloudRow from '@/components/hrdcloud/HrdCloudRow';
// import LoadingDiv from '@/components/common/LoadingDiv';
const hrdLoading = ref(false);
import {goBack} from '@/assets/js/modules/common/common';

export default {
  name: 'MobileCccMain',
  components: {
    MobileBoardReadOnly,
    // CommonBanner,
    // Swiper,
    // SwiperSlide,
    LxpMobileHeader,

  },
  setup() {

    const popups = ref([]);

    initProgressBar();
    const boardId = reactive([
      'ccc-vote',
      'ccc-vote-create',
    ]);

    const store = useStore();
    const lochref = ref(location.href);
    const bannerCode = ref(banerExpsrLocCdDcds.ccc);
    const cloudMapModal = ref(false);
    const items = ref([]);

    const isLoading = ref(false);

    const session = computed(() => store.state.auth.session);
    const sortTypeDcd = computed(() => getDcdOptions('2073'));


    const menu = ref(1);
    const myLikeTotalCnt = ref('0');

    const hrds = ref([]);
    const categories = ref([]);
    const bgImgUrl = require('@/assets/lxp/images/main/kiki_cc.png');


    const params = ref({});
    const tabMenu = ref(0);
    const toggles = reactive({
      sortTyCdDcd: false,
      lvl1Toggle: false
    });


    return {
      isLoading, session, sortTypeDcd, hrds,   cloudMapModal, lochref,
      tabMenu, params, categories,goBack,
      toggles, closeToggles,
      popups,
      items,
      menu,
      myLikeTotalCnt,
      timestampToDateFormat,
      hrdLoading,
      bannerCode,
      boardId,
      bgImgUrl,

    }
  }
};
</script>
